import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import icon from "../assets/logo4.png";

const TopBar = ({ ContainedButton, ButtonTypography }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      // Muda o estado quando o scroll passa de 50px
      setIsScrolled(scrollTop > 50);
    };

    // Escuta o evento de rolagem
    window.addEventListener("scroll", handleScroll);

    // Remove o listener quando o componente é desmontado
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        zIndex: 1000, // Certifique-se de que a barra esteja acima dos outros elementos
        position: "fixed",
        width: "100%",
        top: 0,
        backgroundColor: isScrolled ? "rgba(255, 255, 255, 0.8)" : "#fbf3f7",
        height: "80px",
        filter: isScrolled
          ? "none" // Remove a sombra ao rolar
          : "drop-shadow(11px 14px 17px rgba(37, 56, 92, 0.1))",
        transition: "background-color 0.3s ease, filter 0.3s ease", // Suaviza a transição
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          paddingTop: "10px",
          height: "75px",
        }}
      >
        <Stack direction="row">
          <img
            style={{
              width: 50,
              height: 50,
              marginTop: "0px",
            }}
            src={icon}
            alt=""
          />

          <Typography
            sx={{ fontSize: "36px", fontFamily: "Mada", fontWeight: "400" }}
            Color="#9d1bc3"
          >
            Zap
          </Typography>
          <Typography
            sx={{ fontSize: "36px", fontFamily: "Mada", fontWeight: "700" }}
          >
            Host
          </Typography>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Button
            href="https://zap.wernerhost.com.br/login"
            sx={{
              borderRadius: "10px",
              width: "100px",
              height: "55px",
              textTransform: "none",
            }}
            color="secondary"
          >
            <ButtonTypography>Entrar</ButtonTypography>
          </Button>
          <ContainedButton
            variant="contained"
            Color="#9d1bc3"
            href="https://zap.wernerhost.com.br/signup"
          >
            <ButtonTypography>Teste agora</ButtonTypography>
          </ContainedButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default TopBar;
